import { render, staticRenderFns } from "./developer-photo-section.vue?vue&type=template&id=5de3fac8&scoped=true"
import script from "./developer-photo-section.vue?vue&type=script&lang=js"
export * from "./developer-photo-section.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5de3fac8",
  null
  
)

export default component.exports