<template>
  <div>
    <div class="form--group photo--section row">
      <div class="col-12 col-lg-3">
        <div class="d-flex align-items-center">
          <span>Foto Developer</span>
          <span class="required">*</span>
        </div>
      </div>
      <div class="col-12 col-lg-6" style="position: relative">
        <section-loading :show="loading" />
        <!--ini preview gambar-->
        <!--        <div class="photo&#45;&#45;preview-wrapper" v-show="developerPhoto">-->
        <!--          <photo-preview-->
        <!--            v-for="(photo, index) in listPhoto"-->
        <!--            :key="index"-->
        <!--            :index="index"-->
        <!--            :length="listPhoto.length"-->
        <!--            :photo="photo"-->
        <!--            :isFloorPlan="false"-->
        <!--          />-->
        <!--        </div>-->
        <div v-if="developerPhoto" class="single--photo">
          <img
            width="100%"
            height="auto"
            :src="developerPhoto.src"
            class="photo--image input--margin"
          />
        </div>
        <vue-dropzone
          v-show="false"
          ref="photoDropzone"
          id="dropzone"
          :options="dropzoneOptions"
          @vdropzone-file-added="added"
          @vdropzone-sending="sending"
          @vdropzone-success="success"
          @vdropzone-complete="completed"
        />
        <v-btn
          type="button"
          color="primary"
          class="btn btn-primary--light btn-block"
          @click="triggerDropzone"
        >
          {{ $t('project.upload') }}
        </v-btn>
        <span class="val-error" v-if="validation.hasError('developerPhoto')">{{
          validation.firstError('developerPhoto')
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import vue2Dropzone from 'nuxt-dropzone';
import { mapState } from 'vuex';
import SectionLoading from '@/components/content-loading/section-loading';
import { Validator } from 'simple-vue-validator';

export default {
  name: 'developer-photo-section',
  props: ['isActive'],
  components: {
    vueDropzone: vue2Dropzone,
    SectionLoading,
  },
  validators: {
    developerPhoto(value) {
      if (this.isActive) {
        return Validator.value(value).required(
          this.$i18n.t('errors.addProject.developerPhoto.required'),
        );
      } else {
        return Validator.value(true);
      }
    },
  },
  data() {
    return {
      addedPhoto: true,
      loading: false,
      dropzoneOptions: {
        url: `${this.$store.state.global.apiUrl}/api/file_content/upload_photo`,
        thumbnailWidth: 150,
        maxFilesize: 5,
        thumbnailMethod: 'contain',
        acceptedFiles: '.jpg,.png,.jpeg',
        // headers: {'X-CSRF-TOKEN': self.csrfToken},
        createImageThumbnails: true,
      },
    };
  },
  computed: {
    ...mapState({
      developerPhoto: (state) => state.project.form.developerPhoto,
      apiUrl: (state) => state.global.apiUrl,
      user: (state) => state.global.user,
    }),
  },
  methods: {
    validate() {
      return this.$validate().then(
        function (success) {
          return !!success;
        }.bind(this),
      );
    },
    triggerDropzone() {
      this.$refs.photoDropzone.dropzone.hiddenFileInput.click();
    },
    completed() {
      this.loading = false;
    },
    added() {
      const dropzone = this.$refs.photoDropzone;
      if (dropzone.dropzone.files.length > 1) {
        dropzone.removeFile(dropzone.dropzone.files[0]);
      }
    },
    // eslint-disable-next-line no-unused-vars
    sending(file, xhr, formData) {
      xhr.setRequestHeader('Authorization', `Bearer ${this.$store.state.global.token}`);
    },
    // eslint-disable-next-line no-unused-vars
    success(file, response) {
      console.log('RESPONSE SUCCESS upload', response);
      // let img = file.previewElement.querySelector('img');
      let img = file.previewElement.querySelector('img');
      this.$store.commit('project/form/SET_DEVELOPER_PHOTO', {
        src: img.src,
        uuid: response.data.photo_uuid,
      });
      let dropzone = this.$refs.photoDropzone;
      dropzone.removeFile(file);
    },
  },
};
</script>

<style scoped></style>
